import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'react-emotion'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Main from '../components/Main'
import { rhythm } from '../utils/typography'
import Panel from '../components/panel'

export default () =>   (
  <Layout>
    <Helmet>
      <title>Uh oh page not found</title>
    </Helmet>
    <Main>
      <Panel>
        <h1 css={css`
          margin-top: 2rem;
        `
        }>We're sorry, the page you're looking for cannot be found :(</h1>
      </Panel>
      <Panel>
        <Link to="/about">Learn more about Cruisio</Link>
      </Panel>
    </Main>
  </Layout>
)
